<template>
    <div class="pc-container">
        <div v-if="showModal && once" class="mask">
            <div class="modal animate__animated animate__zoomIn">
                <div class="top">
                    <div class="modal-title">{{$t('Home.TcTitle')}}</div>
                    <i class="el-icon-close" @click="closeModal"></i>
                </div>
                <div v-html="tcInfo" class="modal-content"></div>
            </div>
        </div>
        <div class="box1">
            <img class="home-bg1" src="@/assets/images/pc/index/home-bg1.png" />
            <div class="box1-content animate__animated animate__bounceInLeft">
                <div class="box1-title1">{{$t('Home.TopTitle1')}}</div>
                <div class="box1-title2">{{$t('Home.TopTitle2')}}</div>
                <div class="account-input">
                    <input v-model="account" class="form-input" :placeholder="$t('Home.EmailAddress')" />
                    <div class="start-btn" @click="toRegister">{{$t('Home.StartUsing')}}</div>
                </div>
            </div>
            <video class="video-box" width="302" muted autoplay loop>
                <source :src="videoUrl" type="video/mp4">
            </video>
        </div>
        <div class="notice-box">
            <div class="notice">
                <div class="left">
                    <img class="icon" src="@/assets/images/pc/index/notice.png" />
                    <div class="text">{{notice}}</div>
                </div>
                <div class="more" @click="to('/announcement')">{{$t('Home.SeeMore')}}》</div>
            </div>
        </div>
        <div class="box2">
            <div class="box2-title">{{$t('Home.MarketTrend')}}</div>
            <img class="left-module animate__animated animate__pulse animate__infinite"
                src="@/assets/images/pc/index/left-module.png" />
            <img class="right-module animate__animated animate__pulse animate__infinite"
                src="@/assets/images/pc/index/right-module.png" />
            <el-table :data="productList" style="width: 100%">
                <el-table-column :label="$t('Home.Currency')">
                    <template slot-scope="scope">
                        <div class="coin-box">
                            <img class="coin-icon" :src="scope.row.pic" />
                            <span class="coin-name">{{scope.row.name + '/USDT'}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('Trade.Price')">
                    <template slot-scope="scope">
                        <span
                            :style="{ color: parseFloat(scope.row.price.close) >= parseFloat(scope.row.price.open) ? '#01BD8B' : '#FB474E' }">${{parseFloat(scope.row.price.close)}}</span>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('Home.Change')">
                    <template slot-scope="scope">
                        <span
                            :style="{ color: parseFloat(scope.row.price.close) >= parseFloat(scope.row.price.open) ? '#01BD8B' : '#FB474E' }">
                            {{parseFloat(scope.row.price.close) >= parseFloat(scope.row.price.open) ? '+' : ''}}{{((parseFloat(scope.row.price.close) - parseFloat(scope.row.price.open))/ parseFloat(scope.row.price.open) * 100).toFixed(2)}}%
                        </span>
                        <i :class="['arrow', parseFloat(scope.row.price.close) >= parseFloat(scope.row.price.open) ? 'el-icon-caret-top' : 'el-icon-caret-bottom']"
                            :style="{ color: parseFloat(scope.row.price.close) >= parseFloat(scope.row.price.open) ? '#01BD8B' : '#FB474E' }"></i>
                    </template>
                </el-table-column>
                <el-table-column prop="volume" :label="$t('Home.TradingVolume')">
                    <template slot-scope="scope">
                        <span>${{parseFloat(scope.row.price.total).toFixed(2)}}</span>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('Home.Trend')">
                    <template slot-scope="scope">
                        <div style="height: 55px" :id="scope.row.name + '_USDT'"></div>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('Home.Operate')">
                    <template slot-scope="scope">
                        <div class="exchange-btn" @click="trade(scope.row)">{{$t('Home.Trade')}}</div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="box3">
            <div class="tab-list">
                <div v-for="(item,index) in tabList1" :key="index" class="tab-item">
                    <img class="icon" :src="item.icon" />
                    <div class="right">
                        <div class="num">{{item.num}}</div>
                        <div class="name">{{item.name}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="box4">
            <div id="box4Title" class="box4-title">{{$t('Home.Box4Title')}}</div>
            <div id="box4Desc" class="box4-desc">{{$t('Home.Box4Desc')}}</div>
            <div id="box4Tab" class="tab-list">
                <div v-for="(item,index) in tabList2" :key="index" class="tab-item" @mouseenter="enter(index)"
                    @mouseleave="leave(index)" :style="{ backgroundColor: item.active ? '#5466EF' : '#FFFFFF' }">
                    <img class="icon" :src="item.active ? item.activeIcon : item.icon" />
                    <div class="name" :style="{ color: item.active ? '#FFFFFF' : '#5466EF' }">{{item.name}}</div>
                    <div class="intro" :style="{ color: item.active ? '#D3D8FE' : '#8C8C8C' }">{{item.intro}}</div>
                </div>
            </div>
        </div>
        <div class="box5">
            <div id="box5Title" class="box5-title">{{$t('Home.Box5Title')}}</div>
            <div id="box5Tablist" class="tab-list">
                <div v-for="(item,index) in tabList3" :key="index" :class="['tab-item', 'trade-item']">
                    <img class="icon" :src="item.icon" />
                    <div class="right">
                        <div class="name">{{item.name}}</div>
                        <div class="desc">{{item.desc}}</div>
                        <div class="trade-btn" @click="to(item.url)">{{$t('Home.TradeNow')}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="box6">
            <img class="home-bg4" src="@/assets/images/pc/index/home-bg4.png" />
            <div class="box6-content">
                <div class="box6-title1">{{$t('Home.Box6Title1')}}</div>
                <div class="box6-title2">{{$t('Home.Box6Title2')}}</div>
                <div class="download-wrapper">
                    <el-popover trigger="click" placement="bottom" popper-class="download-popover">
                        <qrcode :value="appUrl" />
                        <div class="app-link">{{appUrl}}</div>
                        <span slot="reference">
                            <img class="android-icon animate__animated animate__pulse animate__infinite"
                                src="@/assets/images/pc/index/android.png" />
                        </span>
                    </el-popover>
                    <el-popover trigger="click" placement="bottom" popper-class="download-popover">
                        <qrcode :value="appUrl" />
                        <div class="app-link">{{appUrl}}</div>
                        <span slot="reference">
                            <img class="iphone-icon animate__animated animate__pulse animate__infinite"
                                src="@/assets/images/pc/index/iphone.png" />
                        </span>
                    </el-popover>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {
            qrcode: () => import('@/components/qrcode.vue')
        },
        data() {
            return {
                showModal: false,
                tcInfo: '',
                account: '',
                notice: '',
                tabList1: [{
                        name: this.$t('Home.AllTradingVolume'),
                        icon: require('@/assets/images/pc/index/tab-icon1.png'),
                        num: '$12,000,000'
                    },
                    {
                        name: this.$t('Home.SupportedCountries'),
                        icon: require('@/assets/images/pc/index/tab-icon2.png'),
                        num: '60+'
                    },
                    {
                        name: this.$t('Home.RegisterNumber'),
                        icon: require('@/assets/images/pc/index/tab-icon3.png'),
                        num: '10,000,000'
                    }
                ],
                tabList2: [{
                        name: this.$t('Home.Box4TabTitles')[0],
                        icon: require('@/assets/images/pc/index/intro-icon1.png'),
                        activeIcon: require('@/assets/images/pc/index/intro-icon1-active.png'),
                        intro: this.$t('Home.Box4TabDescs')[0],
                        active: false
                    },
                    {
                        name: this.$t('Home.Box4TabTitles')[1],
                        icon: require('@/assets/images/pc/index/intro-icon2.png'),
                        activeIcon: require('@/assets/images/pc/index/intro-icon2-active.png'),
                        intro: this.$t('Home.Box4TabDescs')[1],
                        active: false
                    },
                    {
                        name: this.$t('Home.Box4TabTitles')[2],
                        icon: require('@/assets/images/pc/index/intro-icon3.png'),
                        activeIcon: require('@/assets/images/pc/index/intro-icon3-active.png'),
                        intro: this.$t('Home.Box4TabDescs')[2],
                        active: false
                    },
                    {
                        name: this.$t('Home.Box4TabTitles')[3],
                        icon: require('@/assets/images/pc/index/intro-icon4.png'),
                        activeIcon: require('@/assets/images/pc/index/intro-icon4-active.png'),
                        intro: this.$t('Home.Box4TabDescs')[3],
                        active: false
                    }
                ],
                tabList3: [
                    {
                        name: this.$t('Home.Box5TabTitles')[0],
                        icon: require('@/assets/images/pc/index/tab-icon5.png'),
                        desc: this.$t('Home.Box5TabDescs')[0],
                        url: '/PerpetualContract'
                    },
                    {
                        name: this.$t('Home.Box5TabTitles')[1],
                        icon: require('@/assets/images/pc/index/tab-icon4.png'),
                        desc: this.$t('Home.Box5TabDescs')[1],
                        url: '/CurrencyTrading'
                    },
                    {
                        name: this.$t('Home.Box5TabTitles')[2],
                        icon: require('@/assets/images/pc/index/tab-icon6.png'),
                        desc: this.$t('Home.Box5TabDescs')[2],
                        url: '/SecondContract'
                    },
                    {
                        name: this.$t('Home.Box5TabTitles')[3],
                        icon: require('@/assets/images/pc/index/tab-icon7.png'),
                        desc: this.$t('Home.Box5TabDescs')[3],
                        url: '/finance'
                    }
                ],
                appUrl: '',
                videoUrl: ''
            }
        },
        created() {
            const body_width = document.body.clientWidth
            if (body_width <= 800) {
                this.to('/h5')
            }
            
            this.videoUrl = location.href.split('/#/')[0] + '/1.mp4'
            this.getTcInfo()
            this.getNotice()
            this.getAppUrl()
            this.$store.dispatch('getProduct').then(res => {
                import("@/plugins/line").then(({
                    default: drowLine
                }) => {
                    res.forEach(item => {
                        const bali = "#" + item.name + '_USDT'
                        let data = item.kline.map(item => {
                            item = JSON.parse(item)
                            return {
                                time: parseInt(item[0]) * 1000,
                                value: parseFloat(item[4])
                            }
                        })
                        // console.log(data)
                        drowLine(bali, data)
                    })
                })
            })
        },
        mounted() {
            if (!this.$ws.socket) {
                this.$ws.init()
            }
            this.$nextTick(() => {
                this.addAnimation()
            })
        },
        activated() {
            if (!this.$ws.socket) {
                this.$ws.init()
            }
        },
        destroyed() {
            
        },
        computed: {
            connect() {
                return this.$store.state.connect
            },
            
            once() {
                return this.$store.state.once
            },
            
            productList() {
                return this.$store.state.productList
            }
        },
        watch: {
            connect(value) {
                let val = JSON.parse(value)
                if(val.status) {
                    this.$ws.sendMsg('{"type":"join","data":"ticker"}')
                }
            }
        },
        methods: {
            closeModal() {
                this.showModal = false
                this.$store.commit('setOnce',false)
            },
            
            addAnimation() {
                window.onscroll = () => {
                    const box4Title = document.getElementById('box4Title')
                    const box5Title = document.getElementById('box5Title')
                    const box4Desc = document.getElementById('box4Desc')
                    const box4Tab = document.getElementById('box4Tab')
                    if (box4Title.offsetTop - window.pageYOffset < window.innerHeight) {
                        box4Title.classList.add('animate__animated', 'animate__fadeIn')
                    }
                    if (box5Title.offsetTop - window.pageYOffset < window.innerHeight) {
                        box5Title.classList.add('animate__animated', 'animate__fadeIn')
                    }
                    if (box4Desc.offsetTop - window.pageYOffset < window.innerHeight) {
                        box4Desc.classList.add('animate__animated', 'animate__fadeIn')
                    }
                    if (box4Tab.offsetTop - window.pageYOffset < window.innerHeight) {
                        box4Tab.classList.add('animate__animated', 'animate__jackInTheBox')
                    }

                    const box5Tablist = document.getElementById('box5Tablist')
                    if (box5Tablist.offsetTop - window.pageYOffset < window.innerHeight) {
                        const tradeItemList = document.getElementsByClassName('trade-item')
                        for (let i = 0; i < tradeItemList.length; i++) {
                            if (i == 0) {
                                tradeItemList[i].classList.add('animate__animated', 'animate__fadeInTopLeft')
                            } else if (i == 1) {
                                tradeItemList[i].classList.add('animate__animated', 'animate__fadeInTopRight')
                            } else if (i == 2) {
                                tradeItemList[i].classList.add('animate__animated', 'animate__fadeInBottomLeft')
                            } else if (i == 3) {
                                tradeItemList[i].classList.add('animate__animated', 'animate__fadeInBottomRight')
                            }
                        }
                    }
                }
            },

            to(path) {
                this.$router.push({
                    path
                })
            },

            toRegister() {
                this.$router.push({
                    path: '/register',
                    query: {
                        email: this.account
                    }
                })
            },

            trade(row) {
                this.$store.commit('setCurCoin', row)
                this.to('/CurrencyTrading')
            },

            enter(index) {
                this.tabList2[index].active = true
            },

            leave(index) {
                this.tabList2[index].active = false
            },

            getTcInfo() {
                this.$api.setting_lang({
                    slug: 'tc_gg'
                }, 300000).then(res => {
                    if (res.data) {
                        this.tcInfo = res.data
                        this.showModal = true
                    }
                })
            },

            getNotice() {
                this.$api.announcement({}, 300000).then(res => {
                    if (res.data[0]) {
                        this.notice = res.data[0].title
                    }
                })
            },

            getAppUrl() {
                this.$api.setting({
                    slug: 'app_url'
                }, 300000).then(res => {
                    if (res.data) {
                        this.appUrl = res.data
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .pc-container {
        width: 100%;

        .mask {
            position: fixed;
            bottom: 0;
            left: 0;
            z-index: 99;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100vh;
            background-color: rgba(0, 0, 0, .4);

            .modal {
                width: 600px;
                box-sizing: border-box;
                padding: 32px 32px 40px;
                border-radius: 12px;
                background: url(~@/assets/images/pc/index/tc-bg.png) no-repeat;
                background-size: 100% 100%;
                animation-duration: 800ms;

                .top {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    margin-bottom: 32px;

                    .modal-title {
                        max-width: 60%;
                        font-size: 20px;
                        font-weight: bold;
                        color: #000000;
                        text-align: center;
                        word-wrap: break-word;
                    }

                    .el-icon-close {
                        position: absolute;
                        top: 50%;
                        right: 0;
                        transform: translateY(-50%);
                        font-size: 22px;
                        font-weight: bold;
                        color: #6D6F7E;
                        cursor: pointer;
                    }
                }

                .modal-content {
                    width: 100%;
                    min-height: 100px;
                    max-height: 500px;
                    word-wrap: break-word;
                    overflow: auto;
                }
            }
        }

        .box1 {
            position: relative;
            width: 100%;

            .home-bg1 {
                display: block;
                width: 100%;
            }

            .box1-content {
                position: absolute;
                top: 25%;
                left: $box-padding;
                width: 36%;
                animation-delay: 200ms;
                animation-duration: 1000ms;

                .box1-title1 {
                    width: 100%;
                    margin-bottom: 25px;
                    font-size: 36px;
                    font-weight: bold;
                    color: transparent;
                    word-wrap: break-word;
                    background-image: linear-gradient(#14D5F1, #1B80FA);
                    background-clip: text;
                }

                .box1-title2 {
                    width: 100%;
                    margin-bottom: 100px;
                    font-size: 15px;
                    color: #30415E;
                    word-wrap: break-word;
                }

                .account-input {
                    display: flex;
                    align-items: center;
                    width: 100%;

                    .form-input {
                        width: 250px;
                        height: 45px;
                        box-sizing: border-box;
                        padding: 0 15px;
                        border: 0;
                        border-radius: 4px;
                        outline: none;
                        font-size: 14px;
                        color: #000000;
                        background-color: #FFFFFF;
                    }

                    .start-btn {
                        height: 45px;
                        line-height: 45px;
                        box-sizing: border-box;
                        padding: 0 20px;
                        margin-left: 14px;
                        border-radius: 4px;
                        font-size: 14px;
                        color: #FFFFFF;
                        text-align: center;
                        background-color: #5466EF;
                        cursor: pointer;
                    }
                }
            }
            
            .video-box {
                position: absolute;
                top: 50%;
                right: $box-padding + 10px;
                transform: translateY(-50%);
            }
        }

        .notice-box {
            width: 100%;
            box-sizing: border-box;
            padding: 0 $box-padding;
            margin-top: 12px;

            .notice {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                height: 50px;
                box-sizing: border-box;
                padding: 0 16px;
                border-radius: 8px;
                background-color: #F5F6F9;

                .left {
                    display: flex;
                    align-items: center;
                    width: 100%;

                    .icon {
                        min-width: 28px;
                        max-width: 28px;
                        height: 28px;
                        margin-right: 10px;
                    }

                    .text {
                        width: calc(100% - 28px);
                        font-size: 13px;
                        color: #282D3B;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                    }
                }

                .more {
                    margin-left: 50px;
                    font-size: 13px;
                    color: #F4A95A;
                    white-space: nowrap;
                    cursor: pointer;
                }
            }
        }

        .box2 {
            position: relative;
            width: 100%;
            box-sizing: border-box;
            padding: 70px $box-padding 100px;

            .box2-title {
                width: 100%;
                margin-bottom: 60px;
                font-size: 28px;
                font-weight: bold;
                color: #000000;
                text-align: center;
                word-wrap: break-word;
            }

            .left-module {
                position: absolute;
                top: 250px;
                left: 90px;
                width: 110px;
            }

            .right-module {
                position: absolute;
                bottom: 80px;
                right: 65px;
                width: 135px;
            }

            /deep/ .el-table th.is-leaf {
                padding: 12px 0;

                .cell {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 14px;
                    font-weight: normal;
                    color: #6D6F7E;
                    text-align: center;
                }
            }

            /deep/ .el-table td {
                padding: 16px 0;

                .cell {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 15px;
                    color: #000000;
                    text-align: center;
                }
            }

            /deep/ .el-table__empty-block {
                min-height: 100px;

                .el-table__empty-text {
                    line-height: 100px;
                    font-size: 15px;
                }
            }

            /deep/ .el-table--enable-row-hover .el-table__body tr:hover>td {
                background-color: #F3F5FF;
            }

            .coin-box {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                
                .coin-icon {
                    min-width: 22px;
                    max-width: 22px;
                    height: 22px;
                    margin-right: 6px;
                }
                
                .coin-name {
                    font-weight: bold;
                }
            }

            .arrow {
                margin-left: 2px;
                font-size: 13px;
            }

            .exchange-btn {
                height: 30px;
                line-height: 30px;
                box-sizing: border-box;
                padding: 0 16px;
                border-radius: 4px;
                font-size: 13px;
                color: #5466EF;
                text-align: center;
                background-color: #EAF0FC;
                cursor: pointer;

                &:hover {
                    color: #FFFFFF;
                    background-color: #5466EF;
                }
            }
        }

        .box3 {
            display: flex;
            align-items: center;
            width: 100%;
            height: 280px;
            box-sizing: border-box;
            padding: 0 $box-padding;
            background: url(~@/assets/images/pc/index/home-bg2.png) no-repeat;
            background-size: 100% 100%;

            .tab-list {
                display: flex;
                justify-content: space-around;
                width: 100%;

                .tab-item {
                    position: relative;
                    display: flex;
                    align-items: center;
                    max-width: 30%;
                    cursor: default;

                    @keyframes myscale {
                        to {
                            transform: scale(140%, 140%);
                        }
                    }

                    &:hover {
                        animation: myscale 0.4s forwards;
                    }

                    .icon {
                        min-width: 44px;
                        max-width: 44px;
                        height: 44px;
                        margin-right: 8px;
                    }

                    .right {
                        width: 100%;

                        .num {
                            width: 100%;
                            margin-bottom: 4px;
                            font-size: 30px;
                            font-weight: bold;
                            color: #FFFFFF;
                            word-break: break-all;
                        }

                        .name {
                            width: 100%;
                            font-size: 12px;
                            color: #FFFFFF;
                            word-wrap: break-word;
                        }
                    }
                }
            }
        }

        .box4 {
            width: 100%;
            box-sizing: border-box;
            padding: 80px $box-padding 100px;
            background: url(~@/assets/images/pc/index/home-bg3.png) no-repeat;
            background-size: 100% 100%;

            .box4-title {
                width: 100%;
                margin-bottom: 12px;
                font-size: 28px;
                font-weight: bold;
                color: #000000;
                text-align: center;
                word-wrap: break-word;
                animation-delay: 300ms;
                animation-duration: 800ms;
            }

            .box4-desc {
                width: 100%;
                margin-bottom: 80px;
                font-size: 15px;
                color: #6E7790;
                text-align: center;
                word-wrap: break-word;
                animation-delay: 300ms;
                animation-duration: 800ms;
            }

            .tab-list {
                display: flex;
                justify-content: space-between;
                width: 100%;
                animation-delay: 600ms;
                animation-duration: 1500ms;

                .tab-item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 24%;
                    box-sizing: border-box;
                    padding: 30px 20px 60px;
                    border-radius: 2px;
                    cursor: default;

                    .icon {
                        min-width: 45px;
                        max-width: 45px;
                        height: 45px;
                    }

                    .name {
                        max-width: 100%;
                        margin: 16px 0 20px;
                        font-size: 18px;
                        font-weight: bold;
                        text-align: center;
                        word-wrap: break-word;
                    }

                    .intro {
                        max-width: 100%;
                        font-size: 13px;
                        text-align: center;
                        word-wrap: break-word;
                    }
                }
            }
        }

        .box5 {
            width: 100%;
            box-sizing: border-box;
            padding: 90px $box-padding;
            background-color: #C1D1F8;

            .box5-title {
                width: 100%;
                margin-bottom: 80px;
                font-size: 28px;
                font-weight: bold;
                color: #000000;
                text-align: center;
                word-wrap: break-word;
                animation-delay: 300ms;
                animation-duration: 800ms;
            }

            .tab-list {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                animation-delay: 600ms;
                animation-duration: 1500ms;

                .tab-item {
                    display: flex;
                    width: 48.5%;
                    box-sizing: border-box;
                    padding: 40px 25px 45px;
                    margin-left: 3%;
                    background: url(~@/assets/images/pc/index/tab-bg.png) no-repeat;
                    background-size: 100% 100%;
                    box-shadow: 0 4px 12px 4px rgba(0, 0, 0, 0.1);

                    &:nth-child(2n+1) {
                        margin-left: 0;
                    }

                    &:nth-child(n+3) {
                        margin-top: 60px;
                    }

                    .icon {
                        min-width: 68px;
                        max-width: 68px;
                        height: 68px;
                        margin-right: 25px;
                    }

                    .right {
                        width: 100%;
                        box-sizing: border-box;
                        // padding-top: 12px;

                        .name {
                            width: 100%;
                            font-size: 18px;
                            font-weight: bold;
                            color: #262A2F;
                            word-wrap: break-word;
                        }

                        .desc {
                            width: 100%;
                            margin: 16px 0 20px;
                            font-size: 13px;
                            color: #8C8C8C;
                            word-wrap: break-word;
                        }

                        .trade-btn {
                            display: inline-block;
                            height: 35px;
                            line-height: 35px;
                            box-sizing: border-box;
                            padding: 0 16px;
                            border-radius: 4px;
                            font-size: 13px;
                            font-weight: bold;
                            color: #5466EF;
                            text-align: center;
                            background-color: #EAF0FC;
                            cursor: pointer;

                            &:hover {
                                color: #FEFEFF;
                                background-color: #5466EF;
                            }
                        }
                    }
                }

                .trade-item {
                    cursor: default;
                }
            }
        }

        .box6 {
            position: relative;
            width: 100%;

            .home-bg4 {
                display: block;
                width: 100%;
            }

            .box6-content {
                position: absolute;
                top: 42%;
                left: $box-padding;
                transform: translateY(-50%);
                width: 36%;

                .box6-title1 {
                    width: 100%;
                    margin-bottom: 12px;
                    font-size: 28px;
                    font-weight: bold;
                    color: #FFFFFF;
                    word-wrap: break-word;
                }

                .box6-title2 {
                    width: 100%;
                    margin-bottom: 40px;
                    font-size: 14px;
                    color: #FFFFFF;
                    word-wrap: break-word;
                }

                .download-wrapper {
                    display: flex;
                    align-items: center;
                    width: 100%;

                    .android-icon,
                    .iphone-icon {
                        min-width: 28px;
                        max-width: 28px;
                        height: 28px;
                        cursor: pointer;
                    }

                    .android-icon {
                        margin-right: 45px;
                    }
                }
            }
        }
    }
</style>
